import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  debugging = true;
  loader;

  appName = 'Initial Management'

  constructor(
    private router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController
  ) {

  }

  getAppName() {
    return this.appName
  }

  logger(title: any = 'No Title', message: any = 'No Message') {
    if (this.debugging) {
      console.log(title, message)
    }
  }

  async notify(text: any, seconds: number = 2) {
    const toast = await this.toastController.create({
      message: text,
      duration: (seconds * 1000)
    });
    toast.present();
  }

  async doSwal(title: string, text: string, type: any) {
    Swal.fire(title, text, type)
  }

  async load(text: any = 'Please wait', seconds: number = 5) {
    this.loader = await this.loadingController.create({
      message: text,
      duration: (seconds * 1000)
    });
    await this.loader.present();
  }


  async startLoader(text: any = 'Please wait') {
    this.logger('Starting Loader')
    this.loader = await this.loadingController.create({
      message: text
    });
    await this.loader.present();
  }

  endLoader() {
    if (this.loader) {
      this.loader.dismiss();
    }
    this.logger('Ending Loader')
  }

  /**
   * Determine whether a variable is considered to be empty. 
   * A variable is considered empty if it does not exist or if its value equals FALSE
   */
  isEmpty(isEmptyItem) {
    if (isEmptyItem === undefined || isEmptyItem === false) {
      return true;
    } else {
      return false;
    }
  }
}
