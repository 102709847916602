import { SetUser } from './../store/user/user.actions';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';


import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: Observable<any>;

  constructor(
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(s => s.user);
  }

  getUser() {
    return this.store.selectSnapshot(s => s.user);
  }

  login(dataa: any): void {
    this.store.dispatch(new SetUser(dataa));
  }
}