import { Injectable } from '@angular/core';

import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed
} from '@capacitor/core';

import {   GlobalFunctionsService } from './global-functions.service';


const { Device } = Plugins;

const { PushNotifications } = Plugins;

@Injectable()
export class PushNotificationsClass {

    constructor(
        private global: GlobalFunctionsService,
    ) { 

    }

    async getDeviceInformation() {
        const info = await Device.getInfo();
        return info;
    }

    async registerPushNotifications() {
        const deviceInfo = await this.getDeviceInformation();
        console.log('deviceInfo', deviceInfo)
        if (deviceInfo.platform !== 'web') {

            window.localStorage.setItem('platform', deviceInfo.platform);
            window.localStorage.setItem('platform_version', deviceInfo.osVersion);
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();

            // On succcess, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: PushNotificationToken) => {
                    window.localStorage.setItem('gcm_regid', token.value);
                    console.log('token', token)
                    // this.global.doSwal(`Token: ${JSON.stringify(token)}`, token.value, 'success')

                    alert('Push registration success, token: ' + token.value);
                }
            );

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    window.localStorage.setItem('gcm_regid', 'N/A');
                    // alert('Error on registration: ' + JSON.stringify(error));
                }
            );

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotification) => {
                    // alert('Push received: ' + JSON.stringify(notification));
                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: PushNotificationActionPerformed) => {
                    // alert('Push action performed: ' + JSON.stringify(notification));
                }
            );
        } else {
            window.localStorage.setItem('platform', deviceInfo.platform);
            window.localStorage.setItem('platform_version', deviceInfo.osVersion);
            window.localStorage.setItem('gcm_regid', '');
            console.log('Push Notifications not supported on Web');
        }
    }

}
