import { PushNotificationsClass } from './services/push-notifications';
import { GlobalFunctionsService } from './services/global-functions.service';
import { UserService } from './services/user.service';
import { DataServiceService } from './services/dataService.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute } from '@angular/router';

import { MenuController } from '@ionic/angular';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('user-bubble', { static: false }) userBubbleIcon: ElementRef;
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    }//,
    // {
    //   title: 'Profile',
    //   url: '/profile',
    //   icon: 'person'
    // },
    // {
    //   title: 'Contact',
    //   url: '/contact',
    //   icon: 'mail'
    // },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  private displayName = '';
  public user;
  private loggedIn = false;
  userBubbleInitials: any;
  userBubbleColorArray = ['#b47629', '#0F3D51']

  toggle = true

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public dataService: DataServiceService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private global: GlobalFunctionsService,
    public menuCtrl: MenuController,
    private pushNotifications: PushNotificationsClass
  ) {
    this.initializeApp();
    this.router.events.subscribe((e: any) => {
      this.user = this.userService.getUser()
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      console.log('TESTING APP READY')
      // this.pushService()
      this.pushNotifications.registerPushNotifications();
    });
  }

  ngOnInit() {
    this.pushNotifications.registerPushNotifications()
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter')

  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter')
  }

  checkUrl(url){
    return this.router.url.includes(url)
  }

  getAppName() {
    return this.global.getAppName()
  }

  logout() {
    this.userService.login(null)
    this.router.navigate(['/']);

  }

  userBubble() {

    const userName: any = this.user;
    const userArray = [userName.name, userName.surname]
    console.log('userArray', userArray)
    this.userBubbleInitials = (userArray.length > 1) ? userArray[0][0] + userArray[1][0] : userArray[0][0] + userArray[0][1]

    const assignedColour = this.userBubbleColorArray[userName.name.length % this.userBubbleColorArray.length]

  }

  toggleMenu() {
    this.menuCtrl.toggle(); //Add this method to your button click function
    this.toggle = !this.toggle
  }
}
