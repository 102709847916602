
import { IQuiz } from './quiz.model';

// export enum ActionTypes {
//   ADD_QUIZ = '[QUIZ] Add',
//   REMOVE_QUIZ = '[QUIZ] Remove'
// }

export class SetQuiz {
  static readonly type = '[QUIZ] Add Quiz';
  constructor(public payload: IQuiz ) {
  }
}

export class RemoveQuiz {
  static readonly type = '[QUIZ] Remove Quiz';

  constructor(public payload: IQuiz) {}
}
