import { Component, Input, OnInit } from '@angular/core';
import { BrowserOpenOptions } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DomSanitizer } from '@angular/platform-browser';
const { Browser } = Plugins;
@Component({
  selector: 'app-browser',
  templateUrl: './browser.page.html',
  styleUrls: ['./browser.page.scss'],
})
export class BrowserPage implements OnInit {
  temp;
  @Input() url: string;
  constructor(
    public modalController: ModalController,
    public safariViewController: SafariViewController,
    private webview: WebView,
    private sanitizer: DomSanitizer
  ) { }

 async ngOnInit() {
    //await Browser.open({ url:this.url,windowName:'_blank' });
    
  }

  ionViewWillEnter(){
   
  }

  closeModal(){
    this.modalController.dismiss();
  }
  openUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  

}
