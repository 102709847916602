import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/auth/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/auth/reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/profile/children/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./pages/profile/children/account-settings/account-settings.module').then( m => m.AccountSettingsPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/profile/children/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  // {
  //   path: 'create-ticket',
  //   loadChildren: () => import('./pages/home/children/create-ticket/create-ticket.module').then( m => m.CreateTicketPageModule)
  // },
  // {
  //   path: 'view-ticket',
  //   loadChildren: () => import('./pages/home/children/view-ticket/view-ticket.module').then( m => m.ViewTicketPageModule)
  // },
  {
    path: 'browser',
    loadChildren: () => import('./components/browser/browser.module').then( m => m.BrowserPageModule)
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./pages/home/children/dashboards/dashboards.module').then( m => m.DashboardsPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },




  

 



  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
