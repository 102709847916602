import { PayPal } from '@ionic-native/paypal/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IonicStorageModule } from '@ionic/storage';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxsModule } from '@ngxs/store';
import { store } from './store/app-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { PushNotificationsClass } from './services/push-notifications';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { BrowserPage } from './components/browser/browser.page';
import { BrowserPageModule } from './components/browser/browser.module';

import { WebView } from '@ionic-native/ionic-webview/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';

// import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [BrowserPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    NgxPayPalModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserPageModule,
    IonicStorageModule.forRoot(),
    NgxsModule.forRoot( store, {
      developmentMode: !environment.production
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxsStoragePluginModule.forRoot()
  ],
  providers: [ 
    StatusBar,
    SplashScreen,
    DatePipe,
    PushNotificationsClass,
    InAppBrowser,
    PayPal,
    WebView,SafariViewController,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
